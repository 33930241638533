import * as Schemas from './schemas';
import * as Yup from 'yup';

import { FormErrors, FormValues } from '../types';
import { ValidationWorker, YupSchemaName } from './types';

import { expose } from 'comlink';
import { sentenceCase } from 'change-case';

Yup.setLocale({
  mixed: {
    default: 'Invalid value'
  }
});

/**
 * When using a default value for FormData types,
 * required will never do anything as doesn't work on empty string
 */
Yup.addMethod(Yup.string, 'required', function (errorMessage) {
  return this.test('meaningful-value', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value || value.length < 2) {
      const fields = path.split('.');
      const field = sentenceCase(fields[fields.length - 1]);
      return createError({
        path,
        message: errorMessage || `A ${field} is required`
      });
    }

    return true;
  });
});

/**
 * Takes a schema name and values.
 * If the validation passes, the function returns undefined.
 * If the validation doesn't pass, it returns a map of invalid field names to errors.
 */
function validate<T extends FormValues>(
  values: FormValues,
  schemaName: YupSchemaName
): Promise<FormErrors<T>> {
  try {
    const schema = Schemas[schemaName];

    schema.validateSync(values, { abortEarly: false });
    return Promise.resolve({} as FormErrors<T>);
  } catch (err) {
    const errors = (err as Yup.ValidationError).inner.reduce((acc, error) => {
      acc[error.path as keyof T] = error.message;
      return acc;
    }, {} as FormErrors<T>);
    return Promise.resolve(errors);
  }
}

export const sync: ValidationWorker = {
  validate
};

expose(sync);
